
    import { defineComponent, ref, watch, onMounted } from 'vue';

    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import AuthService from "@/services/AuthService";
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import ApiService from '@/services/ApiService';
    import { useStore } from 'vuex';
    import { Actions } from "@/store/enums/StoreEnums";

    import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';

    export default defineComponent({
        name: "filtro-log-acessos",
        components: {
            FilterBase,
            ElConfigProvider,
        },

        setup(props, { emit }) {
            const store = useStore();

            const dataInicialPeriodo = moment().startOf("month").toISOString();
            const dataFInalPeriodo = moment().endOf("day").toISOString();
            const periodoMesAtual = [dataInicialPeriodo, dataFInalPeriodo];
            const valorInicialData = periodoMesAtual;

            let valorInicialCodConcessionaria;
            if(window.localStorage.getItem("id_usuario")){
                valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
            } else {
                valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
            }
            const codConcessionariaSelected:any = ref(valorInicialCodConcessionaria ?? "");
            const datePicker = ref(valorInicialData);

            const userInfo = AuthService.getUsuarioAuth();
            const token = userInfo.token;
            const emitter = useEmitter();

            const activeModal = ref(false);
            const closeModal = ref(false);
            const disabledClick = ref(false);

            onMounted(() => {
                if(store.getters.concessionariasList.length > 0){
                    trataConcessionarias();
                }
            });

            store.watch(() => store.getters.concessionariasList, () => {
                trataConcessionarias();
            }, { deep: true });

            async function trataConcessionarias() {
                const concessionarias = store.getters.concessionariasList;

                optionsConcessionaria.value = concessionarias.map(concessionaria => {
                    return {
                        text: concessionaria.nomeFantasia,
                        value: concessionaria.codConcessionaria,
                    }
                });

                // Inicia filtro e emite dados iniciais
                if(token){
                    if(optionsConcessionaria.value.length > 1){
                        if(codConcessionariaSelected.value){
                            const endData = moment(datePicker.value[1]);
                            console.log('endData',endData)
                            const sameMounthAndYear = moment().isSame(endData, 'month') && moment().isSame(endData, 'year');
                            const endDataTratada = sameMounthAndYear ? moment().endOf("day") : endData.endOf("month").endOf("day");
                            const dataTratada = [moment(datePicker.value[0]).startOf("day").toDate(), endDataTratada];
                            emiteMudanca(dataTratada); 
                            emitter.emit('showHelp');
                        } else {
                            activeModal.value = !activeModal.value;
                            showClose.value = false;
                            codConcessionariaSelected.value = optionsConcessionaria.value[0].value;
                        }
                    } else {
                        codConcessionariaSelected.value = optionsConcessionaria.value[0].value;
                        const endData = moment(datePicker.value[1]);
                        const sameMounthAndYear = moment().isSame(endData, 'month') && moment().isSame(endData, 'year');
                        const endDataTratada = sameMounthAndYear ? moment().endOf("day") : endData.endOf("month").endOf("day");
                        const dataTratada = [moment(datePicker.value[0]).startOf("day").toDate(), endDataTratada];
                        emiteMudanca(dataTratada); 
                        emitter.emit('showHelp'); 
                    }
                };
            };

            watch(datePicker, () => {
                if(datePicker.value == null){
                    datePicker.value = periodoMesAtual;
                    return;
                };
            });

            function desabilitaDatasAnteriores (data){
                const dataAnterior = data.getTime() > new Date("2016-01-01T00:00:00") === data.getTime() > Date.now()
                return dataAnterior
            };
            
            function verificaIntervalo(data) {
                var diff = moment(data[1]).diff(moment(data[0]), "months");
                if (diff <= 24) {
                    const startDate = moment(datePicker.value[0]);
                    const endDate = moment(datePicker.value[1]);
                    
                    // Verifica se as duas datas estão no mesmo mês
                    if (startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year')) {
                    // Se sim, define a data final como a mesma data do final do dia
                    const endDataTratada = moment(datePicker.value[1]).endOf("day");
                    const dataTratada = [startDate.startOf("day").toDate(), endDataTratada.toDate()];
                    emiteMudanca(dataTratada);
                    } else {
                    // Caso contrário, aplica a lógica anterior
                    const sameMounthAndYear = moment().isSame(endDate, 'month') && moment().isSame(endDate, 'year');
                    const endDataTratada = sameMounthAndYear ? moment().endOf("day") : endDate.endOf("month").endOf("day");
                    const dataTratada = [startDate.startOf("day").toDate(), endDataTratada.toDate()];
                    emiteMudanca(dataTratada);
                    }
                    
                    closeModal.value = !closeModal.value;
                } else {
                    Swal.fire({
                        title: "Período inválido!",
                        html: `<h4>Não é possível filtrar um período superior a 24 meses (2 anos)!</h4>`,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok!",
                        customClass: {
                        confirmButton: "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
                        },
                    });
                }
            };

            const lastEmitIsNull = ref(false);

            const helpShow = ref(false);

            const optionsConcessionaria:any= ref([]);
        	const loading = ref(false);


            const modalFiltro:any = ref();
            const showClose = ref(false);

            function retornaFiltroData(dataInicial, dataFinal){
                dataInicial = moment(dataInicial).startOf('day').format("YYYY-MM-DD HH:mm:ss");
                dataFinal = moment(dataFinal).endOf('day').format("YYYY-MM-DD HH:mm:ss");

                const filters = `${dataInicial}/${dataFinal}`;
                console.log("filters", filters)
                return filters;
            };

            async function emiteMudanca(valorData){
                ///// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
                await ApiService.valideTokenTime();
                
                if(!helpShow.value){
                    helpShow.value = true;
                    emitter.emit('showHelp'); 
                };

                if(window.localStorage.getItem("id_usuario")){
                    window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                } else {
                    window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                }

       
                const  dataInicial = moment(periodoMesAtual[0], "YYYY-MM-DD HH:mm:ss")
                const  dataFinal =  moment(periodoMesAtual[1], "YYYY-MM-DD HH:mm:ss")
             
                
                if(valorData == null){
                    if(lastEmitIsNull.value){
                        return;
                    };

                    lastEmitIsNull.value = true;
                    const dataTratada = retornaFiltroData(dataInicial, dataFinal);
                    store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                    store.dispatch(Actions.SET_DATA_DASHBOARD_SELECTED, dataTratada);
                    emitter.emit("filtrar-log-acessos", { data: dataTratada, codConcessionaria: codConcessionariaSelected.value });
                    return;
                };

                lastEmitIsNull.value = false;
                const dataTratada = retornaFiltroData(valorData[0], valorData[1]);
                store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                store.dispatch(Actions.SET_DATA_DASHBOARD_SELECTED, dataTratada);
                emitter.emit("filtrar-log-acessos", { data: dataTratada, codConcessionaria: codConcessionariaSelected.value });
            };

            return {
                helpShow,
                activeModal,
                closeModal,
                showClose,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                periodoMesAtual, 
                lastEmitIsNull, 
                desabilitaDatasAnteriores,
                optionsConcessionaria, 
                codConcessionariaSelected,
                verificaIntervalo,
                disabledClick
            };
        }
    });
